@use '@angular/material' as mat;

/* You can add global styles to this file, and also import other style files */
@import 'bootstrap/scss/bootstrap';
@import 'ppi-theme';
@import 'ppi-colors';

:root {
  --pt-level-one: #d7d7d7ff;
  --pt-level-two: #bebebeff;
  --pt-level-three: #a5a5a5;
  --input-color: #ffff99;
  --input-color-border: black;
  --contrast-text: #edf6ff;
  --green-bg-dark: #45b598;
  --green-bg: #71cdaa;
  --green-bg-light: #ecf8f5;
  --green-text: #00644a;
  --orange-bg-dark: #e9531f;
  --orange-bg: #f0824f;
  --orange-bg-light: #fdeee9;
  --orange-text: #9c2800;
  --table-pad: 0.4rem;
  --pt-table-col-width: 200px !important;
}

html,
body {
  position: relative;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.text-monospace {
  font-family: monospace;
}

.full-width {
  max-width: 100%;
}

.default-width {
  max-width: 1600px;
}

.main-content {
  height: 94%;
}

.table-light-colors {
  tbody > tr:hover {
    background-color: var(--light-background-hover) !important;
  }
}

.table-dark-colors {
  tbody > tr:hover {
    background-color: var(--dark-background-hover) !important;
  }
}

.month-picker .mat-calendar-period-button {
  pointer-events: none;
  background-color: var(--dark-background-hover) !important;
}

.month-picker .mat-calendar-arrow {
  display: none;
  background-color: var(--dark-background-hover) !important;
}

.timesheet-calendar .mat-calendar-period-button {
  pointer-events: none;
  background-color: var(--dark-background-hover) !important;
}

.timesheet-calendar .mat-calendar-arrow,
.timesheet-calendar .mat-calendar-previous-button,
.timesheet-calendar .mat-calendar-next-button {
  display: none;
  background-color: var(--dark-background-hover) !important;
}

.fit-content {
  width: fit-content;
}

.snack-primary {
  background-color: var(--primary-color) !important;
  color: var(--text-primary-darker-color) !important;

  button {
    color: var(--text-primary-darker-color);
  }
}

.snack-accent {
  background-color: var(--accent-darker-color) !important;
  color: var(--text-accent-lighter-color) !important;

  button {
    color: var(--text-accent-lighter-color);
  }
}

.snack-warn {
  background-color: var(--warn-color) !important;
  color: var(--text-warn-darker-color) !important;

  button {
    color: var(--text-warn-darker-color);
  }
}

.snack-yellow {
  background-color: var(--warn-color-secondary) !important;
  color: var(--text-warn-darker-color) !important;

  button {
    color: var(--text-warn-darker-color);
  }
}

.dark-button {
  background-color: #393939;
  color: var(--text-accent-lighter-color);
}

.cdk-overlay-pane {
  width: auto !important;
  min-width: 120px;
  max-width: 750px;
}

.accent-lighter {
  background-color: var(--accent-lighter-color) !important;
}

.mat-calendar-body-cell.selected-date-calendar {
  background-color: var(--primary-color);
  border-radius: 50%;
}

.mat-calendar-body-cell.selected-date-calendar .mat-calendar-body-cell-content {
  color: var(--text-primary-color);
}

mat-form-field input {
  height: 1.125em;
}

.buttons-right {
  display: flex;
  justify-content: flex-end;
}

.dialog-title {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  margin: 0 !important;
}

/* Border above the input field
   Note: we added the class="calculation-input" to all .mat-form-field-appearance-legacy
   elements in the calculation table, so that the style doesn't overwrite all inputs. */
.calculation-input .mat-form-field-infix {
  padding: 0em 0 !important;
  border: 0 !important;
}

/* Wrapper around the input field */
.calculation-input .mat-form-field-wrapper {
  padding-bottom: 0em !important;
}

/* Move input field underline further down because we removed the border. */
.calculation-input .mat-form-field-underline {
  //display: none !important;
  transform: translateY(1.5em);
}

/* remove the underline, if the field is inactive */
.calculation-input.mat-form-field-disabled .mat-form-field-underline,
.calculation-input mat-select[disabled] ~ .mat-form-field-underline {
  display: none !important;
}

/* Make the icon smaller so that its the size of the text */
.costume-icon {
  transform: scale(0.5) !important;
  height: 1em !important;
  line-height: 1 !important;
}

/* Space the icons properly apart */
.costume-icon {
  height: 1em !important;
  width: 2em !important;
}

.calculation-row {
  padding-left: var(--table-pad) !important;
}

.outer-calculation-row {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.leftmost-column {
  width: calc(100% - var(--pt-table-col-width) - var(--table-pad));
}

.cell-right {
  text-align: right !important;
}

.column-padding {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
  outline: solid white var(--table-pad);
}

.column-padding-left-end {
  padding-left: 0.2rem;
  padding-right: 0.1rem;
}

.column-padding-right-end {
  padding-left: 0.1rem;
  padding-right: 0.2rem;
}

.pt-header-level-1 {
  height: 1em;
  background-color: var(--pt-level-one);
}

.pt-header-level-2 {
  height: 1em;
  background-color: var(--pt-level-two);
}

.pt-bg-1 {
  background-color: var(--pt-level-one);
}

.pt-bg-2 {
  background-color: var(--pt-level-two);
}

.pt-bg-3 {
  background-color: var(--pt-level-three);
}
